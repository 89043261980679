<template>
  <div>
    <!-- Table Container Card -->
    <div v-if="componentFormExist">
      <component
        :is="selectedForm.vueComponents.form"
        :etablissement-details="etablissementDetails"
        @onFormSucess="onFormSucess"
        :apiRoutePrefixe="selectedForm.apiRoutePrefixe"
      />
    </div>

    <div v-if="!componentFormExist">
      <h1>Ce formualaire est indisponible pour le moment</h1>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  BImg,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'

import utilsService from '@/services/utils/utils.service'

import formulaireStoreModule from '@/store/formulaire'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'


// all statsform are imported here
const FormEntreesFrontieresTerrestres = () => import(/* webpackPrefetch: true */ '@/views/apps/formulaires/frontieres-terrestres-entrees/FormEntreesFrontieresTerrestres.vue')
const FormSortiesFrontieresTerrestres = () => import(/* webpackPrefetch: true */ '@/views/apps/formulaires/frontieres-terrestres-sorties/FormSortiesFrontieresTerrestres.vue')
const FormAgenceVoyage = () => import(/* webpackPrefetch: true */ './agence-voyage/FormAgenceVoyage.vue')
const FormAgenceTransport = () => import(/* webpackPrefetch: true */ './agence-transport/FormAgenceTransport.vue')
const FormAgenceSportive = () => import(/* webpackPrefetch: true */ './agence-sportive/FormAgenceSportive.vue')
const FormAgenceCulturelle = () => import(/* webpackPrefetch: true */ './agence-culturelle/FormAgenceCulturelle.vue')
const FormLocation = () => import(/* webpackPrefetch: true */ './location/FormLocation.vue')
const FormRestauration = () => import(/* webpackPrefetch: true */ './restauration/FormRestauration.vue')
const FormHotel = () => import('./hotel/FormHotel.vue')
const FormHotelClient = () => import('./ficheEnregistrementClient/hotel/FormHotelClient.vue')
const FormEntreeAeroport = () => import('./ficheEnregistrementClient/entree-aeroport/FormEntreeAeroport.vue')
const FormSortieAeroport = () => import('./ficheEnregistrementClient/sortie-aeroport/FormSortieAeroport.vue')
const FormFicheTourismeInterne = () => import('./tourisme-interne/FormFicheTourismeInterne.vue')
const FormFicheTourismeEmetteur = () => import('./tourisme-emetteur/FormFicheTourismeEmetteur.vue')
const FormFicheTourismeRecepteur = () => import('./tourisme-recepteur/FormFicheTourismeRecepteur.vue')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BCardBody,
    BCardHeader,

    // stats forms
    FormAgenceVoyage,
    FormAgenceTransport,
    FormAgenceSportive,
    FormAgenceCulturelle,
    FormLocation,
    FormRestauration,
    FormHotel,
    FormHotelClient,
    FormEntreesFrontieresTerrestres,
    FormSortiesFrontieresTerrestres,
    FormEntreeAeroport,
    FormSortieAeroport,
    FormFicheTourismeInterne,
    FormFicheTourismeEmetteur,
    FormFicheTourismeRecepteur,
    BImg,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'formulaire', module: formulaireStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  data() {
    return {
      selectedForm: null,
      defaultFormImg: require('@/assets/images/pages/login-default.png'),
      etablissementDetails: utilsService.etablissementDetails(),
    }
  },
  computed: {
    componentFormExist() {
      return this.selectedForm && this.selectedForm.vueComponents
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        const { slug, model } = val.params
        this.selectedForm = utilsService.getFormulaireBySlug(slug)
        console.log('+++ this.selectedForm.apiRoutePrefixe::: ', this.selectedForm.apiRoutePrefixe);
        if (this.componentFormExist && !this.$can('create', this.selectedForm.resource)) {
          this.$router.push('/')
        }
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },
  mounted() {},
  methods: {
    ...mapActions('formulaire', {
      getLastFormCreatedByUser: 'getLastFormCreatedByUser',
    }),
    onFormSucess(data) {
      this.$router.push({
        name: 'formulaires-preview',
        params: { slug: this.selectedForm.slug, ...data.routerParams },
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
